* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* body {
    font-family: Arial, sans-serif;
} */

header {
    background-color: #333;
    color: white;
    padding: 15px;
    text-align: center;
}

header nav a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
}

.banner {
    background: url("/public/static/banner.png") no-repeat center;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.banner h2 {
    font-size: 48px;
}

section {
    padding: 50px;
    text-align: center;
}

.overview {
    background-color: #f4f4f4;
}

.services ul {
    list-style-type: none;
}

.services li {
    margin: 15px 0;
}

.contact form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 auto;
}

.contact form label {
    margin: 10px 0;
}

.contact form input,
.contact form textarea {
    padding: 10px;
    margin-top: 5px;
}

footer {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in-section.visible {
    opacity: 1;
    transform: translateY(0);
}

.font-size-16 {
    font-size: 16pt;
}
.font-size-15 {
    font-size: 15pt;
}
.font-size-14 {
    font-size: 14pt;
}
.font-size-13 {
    font-size: 13pt;
}
.font-size-12 {
    font-size: 12pt;
}
.font-size-11 {
    font-size: 11pt;
}
.font-size-10 {
    font-size: 10pt;
}
.font-size-9 {
    font-size: 9pt;
}
.font-size-8 {
    font-size: 8pt;
}
.font-size-7 {
    font-size: 7pt;
}
.font-size-6 {
    font-size: 6pt;
}

.text-align-justify {
    text-align: justify;
}

@media (max-width: 576px) {
    .banner {
        background: url("/public/static/banner_2.png") no-repeat center;
        background-size: cover;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
    }
}
